import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import styled from 'styled-components';
import { IReview } from '../models/Review';
import Title from '../components/typography/Title';
import Text from '../components/typography/Text';
import { Section } from '../components/Block';
import { Main } from '../components/Main';
import ReviewContainer from '../components/review/ReviewContainer';
import { Review } from '../components/review/Review';
import { useI18n } from '../i18n/useI18n';
import { RemarkNode } from '../models/RemarkNode';
import { ButtonWithArrow } from '../components/Button';

// STYLES --------------------------------------------

const Container = styled(Section)``;

const Subtitle = styled(Text)`
    margin-bottom: 2rem;
`;

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

// ---------------------------------------------------

export interface ReviewsPageProps {
    data: {
        datoCmsReviewsPage: {
            title: string;
            subtitleNode: RemarkNode;
            seoMetaTags: any;
            seeMoreUrl: string;
            seeMoreReviews: string;
        };
        allDatoCmsReview: {
            edges: Array<{ node: IReview }>;
        };
    };
}

export default function ReviewsPage({ data }: ReviewsPageProps): JSX.Element {
    const { language } = useI18n();
    const { title, subtitleNode, seeMoreUrl, seeMoreReviews, seoMetaTags } = data.datoCmsReviewsPage;

    const reviews = data.allDatoCmsReview.edges
        .map(({ node }) => node)
        .map(review => (
            <ReviewContainer key={review.id}>
                <Review currentLanguage={language} {...review} align="left" />
            </ReviewContainer>
        ));

    return (
        <Container as={Main}>
            <HelmetDatoCms seo={seoMetaTags} />
            <Title level={1}>{title}</Title>
            <Subtitle dangerouslySetSanitizedHTML={subtitleNode.childMarkdownRemark.html} />
            {reviews}
            <Actions>
                <ButtonWithArrow as="a" href={seeMoreUrl} target="_blank" rel="noopener noreferrer">
                    {seeMoreReviews}
                </ButtonWithArrow>
            </Actions>
        </Container>
    );
}

export const query = graphql`
    query ReviewsPage($language: String!) {
        datoCmsReviewsPage(locale: { eq: $language }) {
            title
            subtitleNode {
                ...RemarkNode
            }
            seeMoreReviews
            seeMoreUrl
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
        }
        allDatoCmsReview(filter: { locale: { eq: $language } }, sort: { order: DESC, fields: date }) {
            edges {
                node {
                    ...ReviewFields
                }
            }
        }
    }
`;
